
import Vue from 'vue';
import { Story } from '@headlines/types/Story';

interface Methods {
  activatePointer: () => void;
  moveItem: () => void;
}

interface Props {
  story: Story;
  storyIndex: number;
  activeItemIndex: number;
  activeStoryIndex: number;
  isLoading: boolean;
  isPaused: boolean;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  props: {
    story: {
      type: Object,
      required: true,
    },
    activeItemIndex: {
      type: Number,
      required: true,
    },
    activeStoryIndex: {
      type: Number,
      required: true,
    },
    storyIndex: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isPaused: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    activeItemIndex: {
      handler() {
        this.activatePointer();
      },
    },
    activeStoryIndex: {
      handler() {
        this.activatePointer();
      },
    },
  },
  methods: {
    activatePointer() {
      if (this.activeStoryIndex === this.storyIndex) {
        const pointer = (this.$refs.pointer as HTMLElement[])[this.activeItemIndex];

        pointer.style['animationDuration'] = `${this.story.items[this.activeItemIndex].length}s`;

        pointer.addEventListener('animationend', this.moveItem);
      }
    },
    moveItem() {
      this.$emit('move-item', true);
    },
  },
  mounted() {
    this.activatePointer();
  },
});
