
import Vue from 'vue';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import { StoryItem } from '@headlines/types/Story';

interface Data {
  hasBeenActive: boolean;
}

interface Methods {
  toggleVideoItemPlayStatus: (toggle: boolean, resetTimer?: boolean) => void;
  registerEventListener: () => void;
  removeEventListener: () => void;
  videoListener: () => void;
  imageListener: () => void;
  loadingCompleted: () => void;
}

interface Computed {
  videoItem: HTMLVideoElement;
  imageItem: HTMLImageElement;
}

interface Props {
  story: StoryItem;
  toggleVideoStatusState: boolean;
  isActive: boolean;
  isLongPressed: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    AdFormBanner,
  },
  props: {
    story: {
      type: Object,
      required: true,
    },
    toggleVideoStatusState: {
      type: Boolean,
      required: false,
      default: true,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLongPressed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    hasBeenActive: false,
  }),
  watch: {
    toggleVideoStatusState: {
      handler(play) {
        this.toggleVideoItemPlayStatus(play);
      },
    },
    isActive: {
      handler(active, oldValue) {
        if (active) {
          this.hasBeenActive = true;
        }

        if (active && !oldValue && this.videoItem && this.story.media.videoSrc) {
          this.videoItem.src = this.story.media.videoSrc;
          this.videoItem.load();
        }

        if (active) {
          this.registerEventListener();
        } else {
          this.toggleVideoItemPlayStatus(false, true);
          this.removeEventListener();
        }

        if (!active && oldValue && this.videoItem) {
          this.videoItem.src = '';
          this.videoItem.load();
        }
      },
    },
  },
  computed: {
    videoItem() {
      return this.$refs.videoItem as HTMLVideoElement;
    },
    imageItem() {
      return this.$refs.imageItem as HTMLImageElement;
    },
  },
  methods: {
    toggleVideoItemPlayStatus(toggle, resetTimer = false) {
      const videoItem = this.$refs.videoItem as HTMLVideoElement;

      if (!videoItem) {
        return;
      }

      if (toggle) {
        // FIXME: Can throw an error, make proper error handling
        videoItem.play();
      } else {
        videoItem.pause();
      }

      if (resetTimer) {
        videoItem.currentTime = 0;
      }
    },
    registerEventListener() {
      if (this.videoItem) {
        if (this.videoItem.readyState >= 3) {
          this.videoListener();
        } else {
          this.videoItem.addEventListener('loadeddata', this.videoListener);
        }
      } else if (this.imageItem) {
        if (this.imageItem.complete) {
          this.imageListener();
        } else {
          this.imageItem.addEventListener('load', this.imageListener);
        }
      } else {
        this.loadingCompleted();
      }
    },
    removeEventListener() {
      if (this.videoItem) {
        this.videoItem.removeEventListener('loadeddata', this.videoListener);
      } else if (this.imageItem) {
        this.imageItem.removeEventListener('load', this.imageListener);
      }
    },
    videoListener() {
      this.toggleVideoItemPlayStatus(true);
      this.loadingCompleted();
    },
    imageListener() {
      this.loadingCompleted();
    },
    loadingCompleted() {
      this.removeEventListener();
      this.$emit('media-loading-complete');
    },
  },
  mounted() {
    if (this.isActive) {
      this.hasBeenActive = true;
      if (this.videoItem && this.story.media.videoSrc) {
        this.videoItem.src = this.story.media.videoSrc;
        this.videoItem.load();
      }

      this.toggleVideoItemPlayStatus(this.toggleVideoStatusState);
      this.registerEventListener();
    }
  },
  beforeDestroy() {
    if (this.videoItem) {
      this.videoItem.src = '';
      this.videoItem.load();
    }
  },
});
