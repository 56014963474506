import jwplayer from '@media/types/JwPlayer';
import dayjs from 'dayjs';
import { AudioItem } from '../../types/getAudio';
import { VideoItem } from '../../types/getVideo';

import config from '../../config/media.config';

class PlayerAnalytics {
  public _mediaData!: AudioItem | VideoItem;
  public _player!: jwplayer.JWPlayer;
  private _initialPlay: boolean;
  protected _eventMap;
  private _lsa: boolean;
  private _currentPosition: number;

  constructor(player: jwplayer.JWPlayer, mediaData: AudioItem | VideoItem, lsa: boolean) {
    this._mediaData = mediaData;
    this._player = player;
    this._initialPlay = true;
    this._eventMap = config.analytics.eventsMap;
    this._lsa = lsa;
    this._currentPosition = 0;

    this.initAnalytics();
  }

  initAnalytics() {
    this.setEventsListeners();
  }

  getPercent() {
    if (this._player.getPosition()) {
      return Math.floor((this._player.getPosition() / this._player.getDuration()) * 100);
    }
    return 0;
  }

  protected eventParameters(parameters?: Record<string, string | number>) {
    const baseParameters = {
      media_id: this._mediaData.id,
      media_type: this._mediaData.type,
      media_title: this._mediaData.metadata.title || 'no-title',
      media_authors: this._mediaData.metadata.authors?.join() || '',
      media_category: this._mediaData.metadata.tags?.join(' > ') || '',
      media_credit: this._mediaData.metadata.credit || '',
      media_autoplay: this._mediaData.type === 'AUDIO' ? '0' : '1',
      media_muted: this._player.getMute() ? '1' : '0',
      media_mutestart: this._mediaData.type === 'AUDIO' ? '0' : '1',
      media_position: Math.floor(this._player.getPosition()),
    };

    return { ...baseParameters, ...parameters };
  }

  setEventsListeners() {
    for (const [jwEvent, eventName] of Object.entries(this._eventMap)) {
      this._player.on(jwEvent as keyof jwplayer.EventParams, (event: any) => {
        let eventType = eventName;

        const baseParams: { [key: string]: any } = { lsa: this._lsa };

        if (jwEvent === 'play' && this._initialPlay) {
          eventType = this._eventMap.play;
          this._initialPlay = false;
        }
        if (jwEvent === 'adRequest' || jwEvent === 'adImpression') {
          if (event.tag) {
            const mid = this.parseMidFromTag(event.tag as string);
            baseParams.mid = mid;
            if (mid !== '525390') {
              return;
            }
          }
        }

        this.sendEvent(eventType, baseParams);
      });
    }
    const secondsFired: number[] = [];

    this._player.on('time', (time) => {
      this._currentPosition = Math.floor(time.position);

      if ((time.position >= 10 && time.position < 10.5) || (time.position >= 30 && time.position < 30.5)) {
        const eventAction = time.position >= 10 && time.position < 10.5 ? this._eventMap.play10 : this._eventMap.play30;
        const optionalData = {
          time: this._currentPosition,
          lsa: this._lsa,
          beacon: false,
        };

        this.sendEvent(eventAction, optionalData);
      }

      if (this._currentPosition > 0 && this._currentPosition % 300 === 0 && !secondsFired.includes(this._currentPosition)) {
        secondsFired.push(this._currentPosition);
        const eventAction = 'keepAlive';
        this.sendEvent(eventAction);
      }
    });
    // Send event when customer leaves the player page
    this._player.on('beforeComplete', () => this.logVideoEnd());
    this._player.on('remove', () => this.logVideoEnd());

    this._player.on('complete', () => {
      const eventAction = 'keepAlive';
      this.sendEvent(eventAction);
    });

    window.addEventListener('beforeunload', () => this.logVideoEnd());
  }

  gaTitle(mediaData: AudioItem | VideoItem) {
    const div = document.createElement('div');
    div.innerHTML = mediaData.metadata.title ? mediaData.metadata.title.substr(0, 150) : window.location.href.substr(0, 150);
    let timestamp = undefined;

    switch (mediaData.type) {
      case 'STREAM':
        timestamp = mediaData.metadata.streamStartTime ? dayjs(mediaData.metadata.streamStartTime).unix() : undefined;
        break;
      case 'VIDEO':
        timestamp = mediaData.metadata.publishAt ? dayjs(mediaData.metadata.publishAt).unix() : undefined;
        timestamp = timestamp === undefined && mediaData.createdAt ? dayjs(mediaData.createdAt).unix() : undefined;
        break;

      default:
        timestamp = undefined;
        break;
    }

    const cleantitle = div.textContent || div.innerText || div.innerHTML;
    return `${cleantitle} (dv:${mediaData.id}:${timestamp})`;
  }

  logVideoEnd() {
    let eventAction = this._eventMap.secondsPlayed;
    this.sendEvent(eventAction, {
      time: this._currentPosition,
      lsa: this._lsa,
      beacon: true,
    });

    const percent = this.getPercent();
    eventAction = this._eventMap.percentsPlayed;
    this.sendEvent(eventAction, {
      percent,
      lsa: this._lsa,
      beacon: true,
    });
  }
  parseMidFromTag(tag: string) {
    const match = tag.match(/[?&]mid=(\d+)/);
    return match ? match[1] : null;
  }

  public sendEvent(eventName: string, eventOptions: Record<string, unknown> = {}) {
    console.log(eventName, eventOptions);
  }
}

export default PlayerAnalytics;
