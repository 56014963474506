import Service from '@root/common/base/Service';
import { ServiceInterface } from '@root/common/types/service';
import { QueryOptions } from '@apollo/client/core';

import { GetCategoriesWithHeadlines } from '@root/modules/category/types/categories';
import getCategoriesWithHeadlines from '@root/modules/category/graphql/getCategoriesWithHeadlines.graphql';
import getCategories from '@root/modules/category/graphql/getCategories.graphql';

import { getRouteParams } from '@root/common/utils/url';
import { getPageOffset } from '@root/common/utils/getPageOffset';
import getChannelDomain from '@root/modules/channel/utils/getChannelDomain';
import { dataLoader, RedisDataLoaderOptions } from '@root/libs/redis/dataloader/dataLoader';

type ServiceVariables = {
  channel: string | string;
  parent?: string;
  parentCategoryId?: number;
  slug?: string;
  domain?: string | string[];
  language?: string;
  page?: string;
  headlinesLimit?: number | undefined;
  getCount?: boolean;
  getViews?: boolean;
};

type FetchVariables = GetCategoriesWithHeadlines['variables'];
type Response = GetCategoriesWithHeadlines['data'];

export default class CategoryWithHeadlinesService extends Service implements ServiceInterface {
  private fixVariables({ channel, parent, parentCategoryId, slug, page, language, headlinesLimit }: ServiceVariables) {
    const params = getRouteParams({ parent, slug, desiredIdType: 'number' });
    const domain = getChannelDomain(channel);
    const limit = headlinesLimit || 50;

    const offset = getPageOffset(limit, page);
    const variables: GetCategoriesWithHeadlines['variables'] = {
      offset,
      limit,
      parent: params.parent,
      parentCategoryId,
      slug: params.slug,
      domain,
      authorLanguage: language,
      getCount: true,
      getViews: domain === 'www.delfi.lt/video',
    };

    return variables;
  }

  private async fetchCategoryFromSlug(variables: FetchVariables) {
    const options = Object.assign({ query: getCategories }, { variables });
    const dataLoaderOptions: RedisDataLoaderOptions = {
      remote: {
        keyPrefix: 'categories',
      },
    };
    const requestWrapper = async (options: QueryOptions) => {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

      const response = await apiProvider.query<Response>(options);

      return response.data;
    };

    if (variables.offset !== 0) {
      dataLoaderOptions.remote!.gracePeriodMs = 600000; // 10 minutes for pager
    }

    const redisDataLoader = dataLoader<QueryOptions, Response>(requestWrapper, dataLoaderOptions);
    const response: Response = redisDataLoader ? await redisDataLoader.load(options) : await requestWrapper(options);

    if (!response?.categories?.data.length) {
      return false;
    }

    const category = response.categories.data[0];

    return {
      category,
    };
  }

  private async fetchCategoryWithHeadlines(variables: FetchVariables) {
    try {
      const options = Object.assign({ query: getCategoriesWithHeadlines }, { variables });
      const dataLoaderOptions: RedisDataLoaderOptions = {
        remote: {
          keyPrefix: 'category',
        },
      };
      const requestWrapper = async (options: QueryOptions) => {
        const apiProvider = this.createProvider('GraphQL');
        apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

        const response = await apiProvider.query<Response>(options);

        return response.data;
      };

      if (variables.offset !== 0) {
        dataLoaderOptions.remote!.gracePeriodMs = 600000; // 10 minutes for pager
      }

      const redisDataLoader = dataLoader<QueryOptions, Response>(requestWrapper, dataLoaderOptions);
      const response: Response = redisDataLoader ? await redisDataLoader.load(options) : await requestWrapper(options);

      if (!response?.categories?.data.length) {
        this.handleError({ message: 'category.error.not_found', statusCode: 404 });
        return;
      }

      const category = response.categories.data[0];

      return {
        category,
        headlines: response.headlines,
      };
    } catch (e) {
      const error = this.generateErrorData(e);
      this.handleError(error);
    }
  }

  public async fetch(variables: ServiceVariables) {
    if (variables.parent) {
      const domain = variables.domain as string;
      const parentData = await this.fetchCategoryFromSlug({ slug: variables.parent, domain });
      if (parentData) {
        variables.parentCategoryId = parentData.category.id;
      }
    }

    const fixedVariables = this.fixVariables(variables);
    const response = await this.fetchCategoryWithHeadlines(fixedVariables);

    return response;
  }
}
