
import { Fragment } from './Fragment';
import { getPortalCoreSettings } from '@core/utils/initSettings';

interface Attrs {
  title?: {
    html: string;
  };
  body?: {
    content: Record<string, unknown>;
  };
  lead?: {
    html: string;
  };
  author?: {
    html: string;
  };
  template?: string;
}

const fragment = new Fragment();
fragment.setRequiredAttrs(['alignment']);

const FragmentComponent = fragment.createComponent();

export default FragmentComponent.extend({
  name: 'FragmentSidebar',
  data() {
    return {
      classType: null,
      attrs: (this.content.attrs as Attrs) || null,
    };
  },
  beforeCreate() {
    this.$options.components!.ContentFragment = require('@core/components/ContentFragment.vue').default;
  },
  computed: {
    classModifier(): Record<string, boolean> {
      const { sidebar } = getPortalCoreSettings('component');

      return {
        [`fragment-sidebar--border-${sidebar.border.color}`]: sidebar.border.enabled,
        [`fragment-sidebar--border-thick`]: sidebar.border.isThick && sidebar.border.enabled,
        [`fragment-sidebar--border-top`]: sidebar.hasBorderTop,
        [`fragment-sidebar--has-bg`]: sidebar.hasBg,
        [`fragment-sidebar--separated-lead`]: sidebar.isLeadSeparated,
      };
    },
  },
  methods: {
    isImage({ contentType, attrsType }: { contentType?: string; attrsType?: string }) {
      return contentType?.toLowerCase() === 'media' && attrsType?.toLowerCase() === 'image';
    },
  },
});
