import { Data as HeadlinesData } from '@root/modules/headlines/types/headlines';
import { AdFormOptions } from '@ads/types/AdForm';
// import { Data as CategoriesData } from '@root/modules/category/types/categories';

type Settings =
  | {
      icons: boolean;
      lead: boolean;
      titleComment: boolean;
      relatedTitles: boolean;
      author: boolean;
      channel: boolean;
      category: boolean;
    }
  | Record<string, unknown>;

type AdSettings =
  | {
      component: string;
      adType: string;
      data: AdFormOptions;
    }
  | Record<string, unknown>;

// export default function convertHeadlinesToFeed(settings: Settings, headlines: HeadlinesData[], category?: Omit<CategoriesData, 'channel'> | null) {
export default function convertHeadlinesToFeed(settings: Settings, adSettings: AdSettings, headlines: HeadlinesData[], categoryLayout: any, pager: any) {
  const feed = [];

  if (categoryLayout === 'endless') {
    const lentaHeadlinesCount = 6 * pager;
    feed.push({
      attributes: {
        settings: {
          bannerPlacement: 'side',
        },
      },
      blocks: [
        {
          attributes: {
            settings,
          },
          type: 'BlockType102',
          data: {
            items: headlines.splice(0, 5),
          },
        },
        {
          attributes: {
            settings,
          },
          type: 'BlockType101',
          data: {
            items: headlines.splice(0, lentaHeadlinesCount),
          },
        },
        {
          attributes: {
            settings: {
              adType: adSettings.adType,
              data: adSettings.data,
            },
          },
          type: 'BlockType26',
          data: {},
        },
      ],
    });
  } else {
    const stepAmount = Math.ceil(headlines.length / 5);

    for (let step = 0; step < stepAmount; step++) {
      const isLast = step === stepAmount - 1;

      feed.push({
        attributes: {
          settings: {
            bannerPlacement: isLast ? 'side' : 'bottom_and_side',
          },
        },
        blocks: [
          {
            attributes: {
              settings,
            },
            type: 'BlockType102',
            data: {
              items: headlines.splice(0, 5),
            },
          },
        ],
      });
    }
  }

  return feed;
}
