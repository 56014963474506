
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType26';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';

const defaultSettings = {
  component: 'adFormBanner',
  adType: 'mobile',
  data: {},
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    AdFormBanner,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mergedSettings() {
      return { ...this.getSettings, ...this.settings, ...this.data };
    },
    style() {
      return this.data?.data?.style ? JSON.parse(this.data?.data?.style) : this.getSettings.style;
    },
    styleMobile() {
      return this.data?.data?.styleMobile ? JSON.parse(this.data?.data?.styleMobile) : this.getSettings.styleMobile;
    },
    adBlockFallback() {
      return this.data?.data?.adBlockFallback ? JSON.parse(this.data?.data?.adBlockFallback) : null;
    },
  },
});
