import { Route } from 'vue-router';
import { GetChannelConfigFunction } from '@root/libs/init-channel/types';
import { Data as CategoriesData } from '@root/modules/category/types/categories';
import { mergeAdsPlacements } from '@ads/utils/mergeAdsPlacements';

export default function getCategoryAdsSettings({
  $channelConfig,
  route,
  category,
}: {
  $channelConfig: GetChannelConfigFunction;
  route: Route;
  category: CategoriesData;
}) {
  const { id } = $channelConfig('settings');
  const { adForm } = $channelConfig('ads').component;

  // Create keywords data
  const keywords = [`${id}`, `${category.slug}`, `cid-${category.id}`];

  const parentCategory = category.parentCategory || null;
  if (parentCategory && parentCategory.id && parentCategory.id.toString() !== $channelConfig('settings').externalId) {
    // Add parent category slug
    const parentSlug = parentCategory.slug as string;
    keywords.push(parentSlug);

    // Add parent category cid keyword
    const parentCid = `cid-${parentCategory.id}`;
    keywords.push(parentCid);
  }

  const keyValue = [`www.delfi.lt:${id}`];

  // Get page placements
  const { component } = $channelConfig('page').category;
  const categorySlug = route.params.slug;
  const channelAdsConfig = component?.adForm?.filter((ad) => ad.categories.includes(categorySlug)) || [];
  const channelPlacements: Record<string, unknown> = adForm.placements || {};
  const placements = channelAdsConfig[0]?.placements ? mergeAdsPlacements(channelPlacements, channelAdsConfig[0]?.placements) : channelPlacements;
  const disableAds = adForm.disableAds || [];

  return {
    mkw: keywords,
    mkv: keyValue,
    placements,
    disableAds,
  };
}
