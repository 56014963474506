
import Vue from 'vue';
// @ts-ignore
import { directive as clickOutside } from 'v-click-outside-x';

interface Props {
  totalPages: number;
}

interface Data {
  canClickOutside: boolean;
  show: boolean;
  page: number | null;
  error: boolean;
}

interface Methods {
  changePage: () => void;
  handleClickOutside: () => void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  directives: {
    focus: {
      inserted(el, binding) {
        if (binding.value) {
          el.focus();
        }
      },
    },
    clickOutside,
  },
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      canClickOutside: false,
      show: false,
      page: null,
      error: false,
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    changePage() {
      if (!this.page || isNaN(this.page) || this.page > this.totalPages) {
        this.error = true;
        return;
      }

      this.error = false;
      this.$emit('change-page', this.page);
      this.canClickOutside = false;
    },
    handleClickOutside() {
      if (this.canClickOutside) {
        this.$emit('change-page', null);
      }
    },
  },
});
