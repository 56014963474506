import { render, staticRenderFns } from "./SkeletonLoader.vue?vue&type=template&id=465991d7&scoped=true"
import script from "./SkeletonLoader.vue?vue&type=script&lang=ts"
export * from "./SkeletonLoader.vue?vue&type=script&lang=ts"
import style0 from "./SkeletonLoader.vue?vue&type=style&index=0&id=465991d7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "465991d7",
  null
  
)

export default component.exports