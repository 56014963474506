
import { Fragment } from './Fragment';

import ContentImage from '@core/components/UI/ContentImage.vue';

interface Recipe {
  title: {
    text: string;
  };
  body: {
    text: string;
  };
  photo: {
    id: string;
    attributes: {
      metadata: Record<string, unknown>;
      cropperData: Record<string, unknown>;
    };
  };
  preparationTime: number;
}

const fragment = new Fragment();
fragment.setRequiredAttrs(['id']);

const FragmentComponent = fragment.createComponent();

export default FragmentComponent.extend({
  name: 'FragmentRecipe',
  data() {
    return {
      uid: '',
      recipe: (this.content.attrs as unknown as Recipe) || null,
    };
  },
  components: {
    ContentImage,
  },
  computed: {
    cropperData() {
      const cropperData = this.recipe.photo.attributes.cropperData;
      if (!cropperData.xs) {
        cropperData.xs = { w: 280, h: 180 };
      }
      return cropperData;
    },
  },
  methods: {
    convertPrepTime(totalMinutes: number) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      const preptime = hours > 0 ? `${hours} val. ${minutes} min.` : `${minutes} min.`;
      return preptime;
    },
  },
});
