
import Vue from 'vue';
import PaginationTooltip from './PaginationTooltip.vue';
import { QueryResult } from '@root/modules/headlines/types/headlines';

interface Data {
  activePage: number;
  showTooltip: boolean;
  staticPagesAmount: number;
  staticPages: {
    start: number[] | null;
    end: number[] | null;
  };
}

interface Props {
  settings: QueryResult['pager'];
}

interface Computed {
  pages: number;
  prevPage: number | null;
  nextPage: number | null;
  pageAtStart: boolean;
  pageAtEnd: boolean;
}

interface Methods {
  generateStaticPages: () => void;
  goToPage: (page: number | null) => void;
  openTooltip: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    PaginationTooltip,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    const activePage = Number(this.$route.query && this.$route.query.page) || 1;
    return {
      activePage: activePage > 0 ? activePage : 1,
      showTooltip: false,
      staticPagesAmount: 2,
      staticPages: {
        start: null,
        end: null,
      },
    };
  },
  computed: {
    pages() {
      if (!this.settings.count || !this.settings.limit) {
        return 1;
      }

      let pages = Math.ceil(this.settings.count / this.settings.limit);
      // 5000 is content-api max offset support
      const maxPages = Math.floor(5000 / this.settings.limit);
      if (pages >= maxPages) {
        pages = maxPages;
      }
      return (this.settings && pages) || 1;
    },
    prevPage() {
      const isVisible = this.activePage > 1;

      return isVisible ? this.activePage - 1 : null;
    },
    nextPage() {
      const isVisible = !this.settings.count || this.activePage < this.pages;

      return isVisible ? this.activePage + 1 : null;
    },
    pageAtStart() {
      return this.activePage < this.pages - this.staticPagesAmount;
    },
    pageAtEnd() {
      const pageAtEnd = !(this.activePage < this.pages - this.staticPagesAmount);
      return pageAtEnd && this.activePage > this.staticPagesAmount + 1;
    },
  },
  watch: {
    $route() {
      this.activePage = Number(this.$route.query && this.$route.query.page) || 1;
      this.$emit('page-change');
    },
  },
  created() {
    this.generateStaticPages();
  },
  methods: {
    goToPage(page) {
      const isSamePage = Number(this.$route.query.page) === page;

      this.showTooltip = false;

      // Check if changing page is possible
      if (page === 0 || page === null || isSamePage || (this.settings.count && page && page > this.pages)) {
        return;
      }

      const query = { ...this.$route.query, ...{ page: String(page) } };
      this.$router.push({ path: document.location.pathname, query });
      window.scrollTo(0, 0);
    },
    generateStaticPages() {
      const staticPages = {
        start: [] as number[],
        end: [] as number[],
      };

      for (let page = 1; page <= this.staticPagesAmount; page++) {
        staticPages.start.push(page);
        staticPages.end.push(this.pages - this.staticPagesAmount + page);
      }

      this.staticPages = staticPages;
    },
    openTooltip() {
      this.showTooltip = true;
    },
  },
});
