import channels from '@root/modules/channel/config/channel.config';
import getAliasByLocale from '@root/common/utils/getAliasByLocale';
import isRoute from '@root/common/utils/isRoute';

type BuildNavigationUrl = (url: string, fakeDomainEnabled?: boolean, locale?: string) => string;

function buildNavigationDevUrl(url?: string) {
  let pathName;

  if (url) {
    try {
      pathName = new URL(url).pathname;
    } catch (e) {
      console.log('Failed to parse url in buildNavigationDevUrl', e);
    }
  }

  const origin = typeof process !== 'undefined' ? process.client : document.location.origin;

  // URL pathname parameter already contains slash
  return `${origin}${pathName}`;
}

// Build dev or live issue url
const buildNavigationUrl: BuildNavigationUrl = (url, fakeDomainEnabled, locale = 'lt_LT') => {
  let newUrl = url;

  const isTest =
    typeof window === 'undefined' && typeof process !== 'undefined'
      ? process.env.CHANNEL_CONFIG_ENV === 'test'
      : /.test.delfi/.test(document.location.hostname);

  if (!fakeDomainEnabled) {
    return isTest ? newUrl.replace('.delfi', '.test.delfi') : newUrl;
  }

  // TODO: Change after Content-api channel object will return channel url
  const channelConfig =
    channels.find((ch) => {
      const urlHost = url.replace(/^https?:\/\//, '').split('/');
      const chUrlHost = ch.url.replace(/^https?:\/\//, '').split('/');

      return (
        urlHost[0] === chUrlHost[0] &&
        (urlHost[1] === chUrlHost[1] || (!chUrlHost[1] && (isRoute(locale, urlHost[1]) || urlHost[1] === getAliasByLocale(locale, 'page'))))
      );
    }) || null;

  if (channelConfig) {
    newUrl = buildNavigationDevUrl(url);
  }

  return newUrl;
};

export default buildNavigationUrl;
