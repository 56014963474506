import { Data as CategoryData } from '@root/modules/category/types/categories';
import { Context } from '@nuxt/types';
import categoryUrl from '@root/modules/category/utils/buildCategoryUrl';

interface CategoryPathContext {
  domain: string;
  fakeDomainEnabled: boolean;
  query: Context['route']['query'];
  route: Context['route'];
}

// const getCategoryPath = (category: CategoryData, { domain, fakeDomainEnabled, query, route }: CategoryPathContext) => {
const getCategoryPath = (category: CategoryData, { domain, fakeDomainEnabled }: CategoryPathContext) => {
  if (!category) {
    return null;
  }

  const sameChannel = domain === category.channel.url;

  if (sameChannel) {
    return null;
  }

  const url = categoryUrl({ category, domain, fakeDomainEnabled });
  if (url) {
    return url;
  }

  return null;
};

export default getCategoryPath;
