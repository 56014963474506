import { render, staticRenderFns } from "./StoryPointers.vue?vue&type=template&id=100ad69d&scoped=true"
import script from "./StoryPointers.vue?vue&type=script&lang=ts"
export * from "./StoryPointers.vue?vue&type=script&lang=ts"
import style0 from "./StoryPointers.vue?vue&type=style&index=0&id=100ad69d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "100ad69d",
  null
  
)

export default component.exports