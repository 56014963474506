import jwplayer from '@media/types/JwPlayer';
import { AudioItem } from '../../types/getAudio';
import { VideoItem } from '../../types/getVideo';

import { Gtag } from '@analytics/trackers';
import PlayerAnalytics from './BaseAnalytics';

import config from '../../config/media.config';

class gTagTracker extends PlayerAnalytics {
  private _gtag!: Gtag;

  protected _eventMap = {
    play: 'VideoView',
    pause: 'VideoPause',
    idle: 'VideoStop',
    complete: 'PlayTime',
    seek: 'media_seek',
    fullscreen: 'media_fullscreen',
    play10: 'VideoView10',
    play30: 'VideoView30',
    keepAlive: 'KeepAlivePing',
    secondsPlayed: 'PlayTime',
    percentsPlayed: 'ViewPercentage',
    // ad events
    adImpression: 'video_ad_impression',
    adRequest: 'video_ad_request',
  };

  constructor(player: jwplayer.JWPlayer, mediaData: AudioItem | VideoItem, lsa: boolean) {
    super(player, mediaData, lsa);
  }

  initAnalytics() {
    this.initGTag();
    this.setEventsListeners();
  }

  private initGTag() {
    this._gtag = new Gtag();
    this._gtag.init({ containerId: config.analytics.gtag.containerId });
  }

  public sendEvent(eventName: string, optionalData: Record<string, unknown>) {
    const isBigScreen = optionalData && optionalData.lsa ? undefined : 1;

    let value;
    let non_interaction;

    switch (eventName) {
      case 'PlayTime':
        value = optionalData.time;
        non_interaction = false;
        break;
      case 'ViewPercentage':
        value = optionalData.percent;
        non_interaction = false;
        break;
      case 'VideoView':
        value = isBigScreen;
        non_interaction = true;
        break;

      default:
        value = null;
        non_interaction = true;
        break;
    }

    const eventLabel = this.gaTitle(this._mediaData);

    const opt = {
      event_action: eventName,
      event_category: 'VideoPlayer',
      event_label: eventLabel,
      value,
      non_interaction,
      transport_type: optionalData && optionalData.beacon && typeof navigator.sendBeacon === 'function' ? 'beacon' : 'default',
    };

    this._gtag.event('VideoPlayer', opt);
  }
}

export { gTagTracker };
